// Watson colors
export default {
  purple_10: '#EED2FF',
  purple_20: '#D7AAFF',
  purple_30: '#BA8FF7',
  purple_40: '#AF6EE8',
  purple_50: '#9855D4',
  purple_60: '#734098',
  purple_70: '#562F72',
  purple_80: '#412356',
  purple_90: '#311A41',
  purple_100: '#030103',
  teal_10: '#A7FAE6',
  teal_20: '#6EEDD8',
  teal_30: '#41D6C3',
  teal_40: '#00B4A0',
  teal_50: '#008571',
  teal_60: '#006D5D',
  teal_70: '#005448',
  teal_80: '#003C32',
  teal_90: '#012B22',
  teal_100: '#000202',
  green_10: '#C8F08F',
  green_20: '#B4E051',
  green_30: '#8CD211',
  green_40: '#5AA700',
  green_50: '#4B8400',
  green_60: '#2D660A',
  green_70: '#144D14',
  green_80: '#0A3C02',
  green_90: '#0C2808',
  green_100: '#010200',
  red_10: '#FFD2DD',
  red_20: '#FFA5B4',
  red_30: '#FF7D87',
  red_40: '#FF5050',
  red_50: '#E71D32',
  red_60: '#AD1625',
  red_70: '#8C101C',
  red_80: '#6E0A1E',
  red_90: '#4C0A17',
  red_100: '#040001',
  blue_10: '#C0E6FF',
  blue_20: '#7CC7FF',
  blue_30: '#5AAAFA',
  blue_40: '#5596E6',
  blue_50: '#4178BE',
  blue_60: '#325C80',
  blue_70: '#264A60',
  blue_80: '#1D3649',
  blue_90: '#152935',
  blue_100: '#010205',
  yellow_10: '#FDE876',
  yellow_20: '#FDD600',
  yellow_30: '#EFC100',
  yellow_40: '#BE9B00',
  yellow_50: '#8C7300',
  yellow_60: '#735F00',
  yellow_70: '#574A00',
  yellow_80: '#3C3200',
  yellow_90: '#281E00',
  yellow_100: '#020100',
  gray_10: '#E0E0E0',
  gray_20: '#C7C7C7',
  gray_30: '#AEAEAE',
  gray_40: '#959595',
  gray_50: '#777677',
  gray_60: '#5A5A5A',
  gray_70: '#464646',
  gray_80: '#323232',
  gray_90: '#121212',
  gray_100: '#000000',
  white_10: '#FFFFFF',
  white_20: '#FDFDFD',
  white_30: '#F9F9F9',
  white_40: '#F4F4F4',
};
